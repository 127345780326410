export default {
    "title": "🇫🇷 Français",

    "很棒的住宿体验": "Excellent séjour",
    "清爽干净，维护良好": "Propre et bien entretenu",
    "很棒的酒店和员工": "Hôtel incroyable et personnel",
    "价值符合预期": "Valeur conforme aux attentes",
    "卓越的": "Exceptionnel",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Beau terrain, endroit idéal pour une escapade d'un week-end. endroit intéressant",

    "首页": "Accueil",
    "预订": "RÉSERVER",
    "关于": "À propos",
    "发现": "Découvrir",
    "会员": "Adhésion",
    "推荐计划": "Programme de parrainage",
    "个人": "Profil",
    "我的": "Le mien",
    "推荐": "Parrainage",
    "事件": "Événement",
    "登出": "Déconnexion",
    "登录": "Se connecter",
    "注册": "S'inscrire",
    "语言": "Langue",
    "忘记密码": "Oublié",
    "取消": "Annuler",
    "确认": "Confirmer",
    "酒店": "Hôtel",
    "银行卡列表": "Liste des cartes bancaires",
    "银行列表": "Liste des banques",
    "持卡人姓名": "Nom du titulaire de la carte",
    "银行卡号": "Numéro de compte bancaire",
    "支付密码": "Mot de passe de paiement",
    "提交": "Soumettre",
    "Pix列表": "Liste Pix",
    "Pix类型": "Type de Pix",
    "Pix账号": "Compte Pix",
    "账号列表": "Liste des comptes",
    "网络": "Réseau",
    "地址": "Adresse",
    "备注": "Remarque",
    "充值金额": "Montant de la recharge",
    "支付类型": "Type de paiement",
    "支付银行": "Banque de paiement",
    "USDT地址": "Adresse USDT",
    "二维码": "Code QR",
    "充值渠道": "Canal de recharge",
    "充值凭证": "Justificatif de recharge",
    "立即登录": "Se connecter maintenant",
    "用户名": "Nom d'utilisateur",
    "密码": "Mot de passe",
    "邀请码": "Code d'invitation",
    "确认密码": "Confirmer le mot de passe",
    "手机": "Téléphone portable",
    "金额": "Montant",
    "新增": "Nouveau",
    "钱包余额": "Solde du compte",
    "单价": "Prix unitaire",
    "数量": "Nombre",
    "总额": "Total",
    "奖励": "Récompenses",
    "手机号码": "Numéro de téléphone",
    "修改密码": "Modifier le mot de passe",
    "登录密码": "Mot de passe de connexion",
    "当前密码": "Mot de passe actuel",
    "新密码": "Nouveau mot de passe",
    "确认新密码": "Confirmer le nouveau mot de passe",
    "保存修改": "Enregistrer les modifications",
    "充值": "Dépôt",
    "复制": "Copier",
    "分享链接": "Partager le lien",
    "升级": "Mise à niveau",
    "登录时间": "Heure de connexion",
    "信誉分": "Crédibilité",
    "提现": "Retrait",
    "提现账号": "Compte de retrait",
    "你确定要删除此账号吗": "Êtes-vous sûr de vouloir supprimer ce compte?",
    "你确定要删除此地址吗": "Êtes-vous sûr de vouloir supprimer cette adresse?",
    "提示": "Conseil",
    "是": "Oui",
    "否": "Non",
    "全部": "Tout",
    "待处理": "En attente de traitement",
    "冻结中": "En cours de gel",
    "已完成": "Terminé",
    "我们的伙伴": "Nos partenaires",
    "关于我们": "À propos de nous",
    "创建时间": "Temps de création",
    "你确定要登出当前账号吗？": "Êtes-vous sûr de vouloir vous déconnecter du compte actuel?",
    "正在寻找酒店": "Recherche d'un hôtel",
    "旅程价格": "Prix du voyage",
    "佣金": "Commissions",
    "搜索": "Recherche",
    "评分": "Évaluation",
    "评论": "Commentaire",
    "评论-t2": "Critique",
    "你还没有设置支付密码，请前往设置": "Vous n'avez pas encore défini de mot de passe de paiement, veuillez vous rendre dans les paramètres",
    "请输入": "Entrez ici",
    "请选择": "Sélectionnez ici",
    "账户明细": "Détails du compte",
    "等级": "Niveau",
    "每日订单": "Commandes quotidiennes",
    "设置": "Paramètres",
    "语言设置": "Paramètres de langue",
    "客服": "Service client",
    "客服2": "Service",
    "投诉": "Plaintes",
    "投诉客户服务": "Se plaindre du service client",
    "帮助": "Aide",
    "复制成功": "Copie réussie",
    "位置、中心": "Emplacement, centre",
    "你好": "Salut",
    "我的账户": "Mon compte",
    "头像": "Avatar",
    "昵称": "Pseudo",
    "设置昵称": "Définir un pseudo",
    "输入酒店": "Entrer l'hôtel",
    "住宿问答": "Questions et réponses sur l'hébergement",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Si vous souhaitez obtenir plus d'informations sur l'hébergement, veuillez consulter les questions des autres clients",
    "酒店问答_Q1": "Quelle est la différence entre une chambre double et une chambre twin?",
    "酒店问答_A1": "Une chambre double a un lit double et une chambre twin a 2 lits simples. Si une chambre est appelée Double/Twin, elle peut être configurée pour l'un ou l'autre type. Ces informations vous ont-elles été utiles?",
    "酒店问答_Q2": "Que comprend le prix?",
    "酒店问答_A2": "Vous pouvez vérifier si d'autres frais tels que le petit-déjeuner, les taxes ou les frais de service sont inclus. Ces informations peuvent également être trouvées dans votre e-mail de confirmation après la réservation, et vous pouvez également consulter votre réservation dans votre compte.",
    "立即预订可享受酒店特别折扣": "Réservez maintenant avec des remises spéciales sur l'hôtel",
    "最低": "MIN",
    "折扣": "REMISE",
    "订单时间": "Heure de la commande",
    "产品": "Produit",
    "订单数量": "Nombre de commandes",
    "提交订单": "Soumettre la commande",
    "可用余额": "Solde disponible",
    "冻结金额": "Montant gelé",
    "佣金比例": "Taux de commission",
    "获得佣金": "Commission gagnée",
    "完成订单": "Commande terminée",
    "请至少选择一项": "Veuillez sélectionner au moins une option",
    "请先选择星星": "Veuillez d'abord sélectionner l'étoile",
    "公告": "Annonce",
    "邀请好友": "Inviter des amis",
    "邀请好友赚取佣金": "Invitez des amis et gagnez des commissions",
    "条款&协议": "Conditions et accords",
    "注册协议": "Accord d'inscription",
    "我已阅读并同意": "J'ai lu et j'accepte",
    "加载中...": "Chargement...",
    "没有更多数据": "Pas plus de données",
    "信息": "Message",
    "快乐的客户": "Client heureux",
    "快乐的酒店老板": "Propriétaire de l'hôtel heureux",
    "评论1": "Plateforme incroyable avec d'excellentes fonctionnalités, qui vaut vraiment la peine d'être explorée !",
    "评论2": "Service client proactif qui vous guidera si vous n’êtes pas clair sur la plateforme.",
    "评论3": "Plateforme de confiance, ça vaut le coup d'essayer !",
    "评论4": "Parvenez à répertorier mon hôtel ici et obtenez d'excellents résultats marketing.",
    "客户评价": "Avis des clients",
    "查看我们用户的真实评价。": "Découvrez les vrais avis de nos utilisateurs.",
    "银行名称": "Nom de banque",
    "点击下面的金蛋抽取奖励": "Cliquez sur l'œuf d'or ci-dessous pour tirer des récompenses",
    "恭喜您中奖了！": "Félicitations pour votre victoire !",
    "很遗憾，您没有中奖": "Désolé, tu n'as pas gagné",
}