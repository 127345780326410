export default {
    "title": "🇯🇵 日本語",

    "很棒的住宿体验": "素晴らしい滞在体験",
    "清爽干净，维护良好": "さわやかで清潔、メンテナンスが良好",
    "很棒的酒店和员工": "素晴らしいホテルとスタッフ",
    "价值符合预期": "期待に沿った価値",
    "卓越的": "卓越した",
    "美丽的土地，周末度假的好去处。 有趣的地方": "美しい土地、週末の休暇に最適な場所。 興味深い場所",

    "首页": "ホーム",
    "预订": "予約",
    "关于": "について",
    "发现": "発見する",
    "会员": "メンバーシップ",
    "推荐计划": "紹介プログラム",
    "个人": "プロフィール",
    "我的": "マイ",
    "推荐": "紹介",
    "事件": "イベント",
    "登出": "ログアウト",
    "登录": "ログイン",
    "注册": "登録",
    "语言": "言語",
    "忘记密码": "パスワードを忘れた",
    "取消": "キャンセル",
    "确认": "確認",
    "酒店": "ホテル",
    "银行卡列表": "銀行カードリスト",
    "银行列表": "銀行リスト",
    "持卡人姓名": "カードホルダー名",
    "银行卡号": "銀行口座番号",
    "支付密码": "支払いパスワード",
    "提交": "提出する",
    "Pix列表": "Pixリスト",
    "Pix类型": "Pixタイプ",
    "Pix账号": "Pixアカウント",
    "账号列表": "アカウントリスト",
    "网络": "ネットワーク",
    "地址": "住所",
    "备注": "備考",
    "充值金额": "チャージ金額",
    "支付类型": "支払いタイプ",
    "支付银行": "支払い銀行",
    "USDT地址": "USDTアドレス",
    "二维码": "QRコード",
    "充值渠道": "チャージチャネル",
    "充值凭证": "チャージバウチャー",
    "立即登录": "今すぐログイン",
    "用户名": "ユーザー名",
    "密码": "パスワード",
    "邀请码": "招待コード",
    "确认密码": "パスワードを認証する",
    "手机": "携帯電話",
    "金额": "金額",
    "新增": "新規追加",
    "钱包余额": "アカウント残高",
    "单价": "単価",
    "数量": "数量",
    "总额": "合計",
    "奖励": "報酬",
    "手机号码": "電話番号",
    "修改密码": "パスワード変更",
    "登录密码": "ログインパスワード",
    "当前密码": "現在のパスワード",
    "新密码": "新しいパスワード",
    "确认新密码": "新しいパスワードを確認",
    "保存修改": "変更を保存",
    "充值": "チャージ",
    "复制": "コピー",
    "分享链接": "リンクを共有",
    "升级": "アップグレード",
    "登录时间": "ログイン時間",
    "信誉分": "信用スコア",
    "提现": "引き出し",
    "提现账号": "引き出し口座",
    "你确定要删除此账号吗": "このアカウントを削除してもよろしいですか？",
    "你确定要删除此地址吗": "この住所を削除してもよろしいですか？",
    "提示": "ヒント",
    "是": "はい",
    "否": "いいえ",
    "全部": "すべて",
    "待处理": "処理待ち",
    "冻结中": "凍結中",
    "已完成": "完了",
    "我们的伙伴": "パートナー",
    "关于我们": "私たちについて",
    "创建时间": "作成日時",
    "你确定要登出当前账号吗？": "現在のアカウントからログアウトしてもよろしいですか？",
    "正在寻找酒店": "ホテルを探しています",
    "旅程价格": "旅行価格",
    "佣金": "手数料",
    "搜索": "検索",
    "评分": "評価",
    "评论": "コメント",
    "评论-t2": "レビュー",
    "你还没有设置支付密码，请前往设置": "支払いパスワードが設定されていません。設定に移動してください",
    "请输入": "ここに入力してください",
    "请选择": "ここを選択してください",
    "账户明细": "アカウント詳細",
    "等级": "レベル",
    "每日订单": "毎日の注文",
    "设置": "設定",
    "语言设置": "言語設定",
    "客服": "カスタマーサポート",
    "客服2": "サービス",
    "投诉": "クレーム",
    "投诉客户服务": "カスタマーサービスにクレームを申し立てる",
    "帮助": "ヘルプ",
    "复制成功": "コピー成功",
    "位置、中心": "位置、中心",
    "你好": "こんにちは",
    "我的账户": "マイアカウント",
    "头像": "アバター",
    "昵称": "ニックネーム",
    "设置昵称": "ニックネームを設定する",
    "输入酒店": "ホテルを入力してください",
    "住宿问答": "宿泊に関する質問と回答",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "宿泊に関する詳細情報をお探しの場合は、他のゲストの質問をご覧ください",
    "酒店问答_Q1": "ダブルルームとツインルームの違いは何ですか？",
    "酒店问答_A1": "ダブルルームはダブルベッドが1つあり、ツインルームはシングルベッドが2つあります。部屋がダブル/ツインと呼ばれる場合、どちらのタイプにも設定できます。この情報は役に立ちましたか？",
    "酒店问答_Q2": "価格には何が含まれますか？",
    "酒店问答_A2": "朝食、税金、サービス料などのその他の料金が含まれているかどうかを確認できます。この情報は予約後の確認メールにも記載されており、アカウントで予約を確認することもできます。",
    "立即预订可享受酒店特别折扣": "今すぐ予約して特別なホテル割引を受ける",
    "最低": "最低",
    "折扣": "割引",
    "订单时间": "注文時間",
    "产品": "製品",
    "订单数量": "注文数",
    "提交订单": "注文を送信する",
    "可用余额": "利用可能残高",
    "冻结金额": "凍結金額",
    "佣金比例": "手数料率",
    "获得佣金": "手数料を獲得する",
    "完成订单": "注文を完了する",
    "请至少选择一项": "少なくとも1つを選択してください",
    "请先选择星星": "最初に星を選択してください",
    "公告": "お知らせ",
    "邀请好友": "友達を招待する",
    "邀请好友赚取佣金": "友達を招待して手数料を稼ぐ",
    "条款&协议": "条項と規約",
    "注册协议": "登録規約",
    "我已阅读并同意": "私は読んで同意しました",
    "加载中...": "読み込み中...",
    "没有更多数据": "データがありません",
    "信息": "メッセージ",
    "快乐的客户": "幸せなクライアント",
    "快乐的酒店老板": "ハッピーホテルのオーナー",
    "评论1": "優れた機能を備えた素晴らしいプラットフォームなので、試してみる価値があります。",
    "评论2": "プラットフォームについて不明な点がある場合に案内する、プロアクティブなカスタマー サービス。",
    "评论3": "信頼できるプラットフォームなので、試してみる価値があります。",
    "评论4": "私のホテルをここに掲載し、マーケティングで素晴らしい成果を収めることができました。",
    "客户评价": "お客様のレビュー",
    "查看我们用户的真实评价。": "ユーザーからの実際のレビューをご覧ください。",
    "银行名称": "銀行名",
    "点击下面的金蛋抽取奖励": "下の金の卵をクリックして報酬を獲得してください",
    "恭喜您中奖了！": "優勝おめでとうございます！",
    "很遗憾，您没有中奖": "ごめんなさい、勝てませんでした",
}