<script setup>
import { nextTick, onMounted, watch } from 'vue'
import { t } from '@/i18n'
import { useRoute, useRouter } from 'vue-router'
import { isAuthRef, is_combo_show } from '@/model/user'
import UiFooter from "./components/UiFooter.vue"
import WiToast from "./components/WiToast/toast.vue"
import WiDialog from "./components/WiDialog/dialog.vue"

const route = useRoute()
const router = useRouter()

const APPNAME = process.env.VUE_APP_NAME

watch(()=> isAuthRef.value, (newx)=> {
  if(!newx && route.meta.loginRequired) {
    router.replace({
      path: '/login',
      query: {
        next: encodeURI(route.fullPath)
      }
    })
  }
}, {immediate: true, deep: true})


onMounted(()=> {
  nextTick(()=> {
    document.title = APPNAME
  })
})
</script>

<template>
  <div id="app" class="min-h-screen flex flex-col">
    <router-view class="grow" />
    <UiFooter v-if="route.meta.tabar"></UiFooter>
    <WiDialog></WiDialog>
    <WiToast></WiToast>
    <div v-if="is_combo_show" class="w-full h-full p-4 fixed left-0 top-0" style="z-index: 99999999;">
      <em class="w-full h-full bg-black/50 block absolute left-0 top-0"></em>
      <div class="w-full h-full overflow-y-auto relative flex items-center justify-center">
        <div class="w-full max-w-3xl bg-default text-default rounded-3xl">
          <div class="p-4">
            <h5 class="text-lg text-center font-bold">NOTICE</h5>
          </div>
          <article class="px-5 py-10 text-center">
            <p>Congratulations! You get 5 times commission of 5 star packages. Please contact customer service to recharge!</p>
          </article>
          <div class="px-4 pb-6 flex justify-center">
            <button @click="is_combo_show = false" class="h-10 px-4 ctx-theme rounded">
              <span>{{t('确认')}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>